<template>
  <RightSideBar
    v-if="openModal"
    submit="Add"
    :loading="loading"
    @submit="add"
    @close="$emit('close')"
    button-class="bg-dynamicBackBtn text-white"
  >
    <template v-slot:title>
      <p class="text-darkPurple">
        Import Privileges
      </p>
    </template>
    <template v-slot:subtitle>
      <p class="font-semibold pb-3 text-darkPurple">
        Import access control settings from existing role.
      </p>
      <p class="pb-2 text-jet">
        Select a role to see it's access settings.
      </p>
      <c-select
        variant="w-full mb-4"
        :options="defaultRoles"
        @onChange="getRolesPriviledges($event)"
      />
    </template>
    <div style="height:100%" v-if="loadingModules">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-if="!loadingModules && modules.length > 0">
      <p class="text-jet">
        Apps and Access
      </p>
      <div class="flex mb-3">
        <p class="flex-grow mt-1 text-xs">
          {{ selected.length + " Selected" }}
        </p>
        <div class="flex">
          <p>Import All App Access</p>
          <checkbox
            class="mt-1 ml-2"
            v-model="checkAll"
            value="all"
            checkbox-size="height:16px; width:16px"
          />
        </div>
      </div>
      <divider />
      <div
        v-for="(item, index) in modules"
        :key="index"
        class="flex flex-col pl-3 mt-4 container "
        :class="item.selected ? 'opacity-50 bg-cultured ' : ''"
      >
        <div class="flex mt-3 items-center">
          <custom-image :slug="item.slug" class="mr-3" />
          <div class="flex flex-col flex-grow">
            <p class="font-semibold">
              {{ item.name }}
            </p>
          </div>
          <div>
            <checkbox
              v-model="selected"
              :value="item"
              checkbox-size="height:16px; width:16px"
            />
          </div>
        </div>
        <divider class="mx-5 mt-5" border-dash />
      </div>
    </div>
  </RightSideBar>
</template>

<script>
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import RightSideBar from "@/components/RightSideBar";
import CustomImage from "@/components/CustomImage";
import Checkbox from "@/components/Checkbox";
import Divider from "../../../../components/divider";

export default {
  components: {
    RightSideBar,
    CustomImage,
    Checkbox,
    CSelect,
    Divider
  },

  props: {
    openModal: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      selected: [],
      defaultRoles: [],
      checked: [],
      loading: true,
      loadingModules: false,
      modules: []
    };
  },
  computed: {
    checkAll: {
      get() {
        return this.modules
          ? this.selected.length === this.modules.length
          : false;
      },
      set(value) {
        const checked = [];
        if (value) {
          this.modules.forEach(module => {
            checked.push(module);
          });
        }
        this.selected = checked;
      }
    }
  },
  methods: {
    add() {
      this.$emit("selectedModule", this.selected);
      this.$emit("close");
      this.selected = [];
    },
    async getRolesPriviledges(id) {
      this.loadingModules = true;
      const response = await this.$_NewGetUpdateRoles(id);
      this.modules = [];
      response.data.modules.forEach(item => {
        if (item.hasPrivilege) {
          this.modules.push(item);
        }
      });
      this.loadingModules = false;
    },
    getRole(query) {
      this.loading = true;
      this.$_NewGetOrgRoles(query)
        .then(result => {
          this.defaultRoles = result.data.roles;
          this.loading = false;
        })
        .catch(err => {
          throw new Error(err.message);
        });
    }
  },
  mounted() {
    this.getRole("");
  }
};
</script>

<style scoped>
.container {
  height: 64px;
}
</style>
