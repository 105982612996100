<template>
  <RightSideBar
    v-if="openModal"
    submit="Add"
    @submit="add"
    @close="$emit('close')"
    button-class="bg-dynamicBackBtn text-white"
  >
    <template v-slot:title>
      <p class="text-darkPurple">
        Add App(s)
      </p>
    </template>
    <template v-slot:subtitle>
      <p class="font-semibold pb-3 text-darkPurple">
        Grant access to selected apps.
      </p>
    </template>
    <div>
      <p class="text-darkPurple">
        Apps
      </p>
      <scroll-container height="500px">
        <div
          v-for="(item, index) in modulesData"
          :key="index"
          class="flex flex-col px-3 mt-4 container "
          :class="item.selected ? 'opacity-50 bg-cultured ' : ''"
        >
          <div class="flex mt-3 items-center">
            <custom-image :slug="item.slug" class="mr-3" />
            <div class="flex flex-col flex-grow">
              <p class="font-semibold">
                {{ item.name }}
              </p>
            </div>
            <div>
              <checkbox
                v-model="selected"
                :value="item"
                checkbox-size="height:16px; width:16px"
              />
            </div>
          </div>
        </div>
      </scroll-container>
    </div>
  </RightSideBar>
</template>

<script>
import RightSideBar from "@/components/RightSideBar";
import CustomImage from "@/components/CustomImage";
import Checkbox from "@/components/Checkbox";
import ScrollContainer from "@/components/ScrollContainer";

export default {
  components: { RightSideBar, CustomImage, Checkbox, ScrollContainer },

  props: {
    openModal: {
      type: Boolean,
      default: false
    },
    modulesData: {
      type: Array,
      default() {
        return [];
      }
    },
    selectedModules: {
      type: Array,
      default() {
        return [];
      }
    },
  },
  data() {
    return {
      selected: []
    };
  },
 
  watch: {
    openModal() {
      this.selected = this.selectedModules;
    }
  },
  methods: {
    add() {
      this.$emit("selectedModules", this.selected);
      this.selected = [];
    }
  },
  mounted() {}
};
</script>

<style scoped>
.container {
  height: 64px;
  border: 1px solid #878e99;
  box-sizing: border-box;
  border-radius: 5px;
}
</style>
